<template>
  <div
    :class="[
      'wrapper-multiselect-options',
      {
        hasValue: value.length > 0,
        active,
        singleSelect: !isMulti,
        borderError: isError,
        isTags: variant === 'tags',
      },
    ]"
  >
    <!-- Value Tags -->
    <div
      v-if="value.length > 0 && isMulti"
      class="wrapper-tags"
      @click="onFocus"
    >
      <div
        v-for="(option, index) in value"
        :key="index"
      >
        <div
          v-show="index < 3 || isShowChips"
          class="multiselect-tags"
          @click="onExpanded"
        >
          <span>{{ getLabel(option) }}</span>
          <div
            class="close-line"
            @click="() => onDelete(option)"
          >
            <img
              :src="`/images/close_modal.svg`"
              alt="Close Line"
              class="close-icon"
            >
          </div>
        </div>
      </div>
      <div
        v-show="value.length > 3 && !isShowChips"
        class="multiselect-tags-count cursor-pointer"
        @click="onSeeAll"
      >
        {{ `+${value.length - 3}` }}
      </div>
    </div>
    <div
      class="comp-dropdown-chevron"
      @click="onFocus"
    >
      <img
        :src="`/images/chevron_down.svg`"
        class="arrow-down-icon-th"
        alt="arrow down"
      >
    </div>

    <!-- Single Select -->
    <div
      v-if="value.length > 0 && !isMulti"
      class="single-select"
      @click="onFocus"
    >
      <span :class="{ 'handle-long-text' : isHandleLongText }">{{ value[0].label }}</span>
    </div>

    <!-- Input -->
    <div class="wrapper-input">
      <input
        ref="input"
        v-model="keyword"
        :class="['input', { 'isShadow' : isRemoveShadow }]"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="name"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput(keyword)"
        @keypress="onSearch"
        @paste="onSearch"
      >
    </div>

    <!-- List Options -->
    <div>
      <ul
        v-if="options.length && options.length > 0 && !isLoading && isAutoFilter"
        class="list-options"
      >
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          :class="['value-option', { selected: isSelected(option) }]"
          @mousedown="() => selectOptions(option)"
        >
          <span>{{ option.label }}</span>
        </li>
      </ul>
      <ul
        v-else-if="options.length && options.length > 0 && !isLoading && !isAutoFilter"
        class="list-options"
      >
        <li
          v-for="(option, index) in options"
          :key="index"
          :class="['value-option', { selected: isSelected(option) }]"
          @mousedown="() => selectOptions(option)"
        >
          <span>{{ option.label }}</span>
        </li>
      </ul>
      <div
        v-else-if="isLoading"
        class="list-options"
      >
        <loader
          position-left="40%"
          color-class="l-grey"
        />
      </div>
      <div
        v-else
        class="list-empty"
      >
        {{ emptyText }}
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '../new-loader/Loader';

export default {
  name: 'DropdownSelect',
  components: { Loader },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'tags',
    },
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
    isRemoveShadow: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isAutoFilter: { // if true, will filter options based on keyword , otherwise will show all options
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'dropdown-input',
    },
    emptyText: {
      type: String,
      default: 'Apa yang Anda cari tidak ditemukan atau tidak ada.',
    },
    isDeleteOnSelected: {
      type: Boolean,
      default: true,
    },
    isHandleLongText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyword: '',
      active: false,
      isShowChips: false,
      optionsData: [],
    };
  },
  computed: {
    filteredOptions() {
      return this.optionsData.filter(option => option.label.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0);
    },
  },
  methods: {
    getLabel(option) {
      if (option.label.lastIndexOf('-') >= 0) {
        return option.label.substr(0, option.label.lastIndexOf('-')).trim();
      }
      return option.label;
    },
    isSelected(option) {
      return this.value?.some(item => item.value === option.value);
    },
    selectOptions(value) {
      if (this.isSelected(value) && this.isDeleteOnSelected) {
        return this.remove(value);
      }

      const selected = this.value;
      if (this.isMulti) {
        selected.push(value);
      } else {
        selected.splice(0, 1, value);
      }

      this.$emit('input', selected);
    },
    remove(option) {
      this.$emit(
        'input',
        this.value?.filter(val => val.value !== option.value),
      );
    },
    onDelete(option) {
      return this.remove(option);
    },
    onFocus() {
      if (this.disabled) {
        return;
      }
      this.optionsData = this.options;
      this.active = true;

      const listTags = document.querySelectorAll('.multiselect-tags');
      listTags.forEach((item, idx) => {
        const elTags = item.parentElement?.querySelector('.multiselect-tags');
        if (idx > 2 && elTags) {
          elTags.classList.add('hasExpanded');
        }
      });

      this.$nextTick(() => {
        const { input } = this.$refs;
        input.focus();
      });
      this.$emit('open');
    },
    onBlur() {
      this.active = false;
      this.keyword = '';
      if (this.variant === 'tags') this.isShowChips = false;
      this.$emit('close');
    },
    onSeeAll() {
      this.isShowChips = !this.isShowChips;
    },
    onExpanded(event) {
      const { currentTarget } = event;
      currentTarget.classList.toggle('hasExpanded');
    },
    onSearch(event) {
      if (!this.isSearch) {
        event.preventDefault();
      }
    },
    onInput(keyword) {
      this.$emit('input-search', keyword);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
